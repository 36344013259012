@import (reference) '../../../style/variables';
@import (reference) '../../../style/colors';

.toolbar-container {
    display: flex;
    align-items: center;
    background-color: #0a133b;
    box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.3);
    justify-content: space-between;
    height: 40px;
    margin: 0 30px;
}

.d3 {
    &-container {
        position: relative;
    }
    &-tooltip {
        font-size: 10px;
        color: @black;
        width: fit-content;
        padding: @gap;
        background-color: @white;
        border: 1px solid @clickable;
        border-radius: 8px;
        opacity: 0;
        position: absolute;
        &-content {
            display: flex;
            align-items: center;
            & ~ & {
                margin-top: @gap;
            }
        }
        &-bold {
            font-weight: 600;
            margin-left: @gap-half;
        }
        &-text {
            margin-right: @gap;
        }
        &-icon:not(:last-child) {
            margin-right: @gap;
        }
    }
}
