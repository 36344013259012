@import (reference) '../../../style/colors';
@import (reference) '../../../style/variables';

.search-results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .parsed-query {
        margin-top: @gap;
        font-size: 12px;
        color: darkorange;
    }
    .results-section {
        width: 100%;
        margin-top: @gap-double;
    }

    .see-image-container {
        display: flex;
        align-items: center;
        cursor: pointer;
        .see-image-icon {
            margin-right: 5px;
        }
        .see-image-text {
            font-size: 12px;
        }
    }
    .no-results {
        margin-top: 10px;
        font-size: 12px;
    }
}

input[type='search']::-webkit-search-cancel-button {
    cursor: pointer;
}
