@import '../../style/colors.less';

.auto-group-container {
    .suggested-count {
        min-width: 150px;
    }
    min-height: 66px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .auto-group-buttons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-grow: 1;
        margin: 0 15px;
        .auto-group-button {
            margin: 3px 8px 3px 0px;
            width: 120px;
            &.selected {
                background-color: darken(@clickable, 10%);
                color: @white;
            }
        }
    }

    .close-icon {
        margin-left: 12px;
        min-width: 25px;
    }
}
