@import (reference) '../../style/colors';
@import (reference) '../../style/variables';

.popup-image-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: @gap * 6;
    padding: 0 12px;
    border-radius: 5px;
    background-color: @dark-gray;
    position: relative;

    .left {
        display: flex;
        align-items: center;
        font-size: 16px;

        color: rgba(255, 255, 255, 0.6);

        .image-position-indicator {
            margin-left: 10px;
            white-space: nowrap;
        }
    }

    .middle {
        color: #dedede;

        display: flex;
        align-items: center;
        overflow: hidden;
        .image-name {
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
            text-align: center;
            font-weight: bold;
        }
    }

    .right {
        display: flex;
        align-items: center;
        min-width: 150px;

        .tree-select-container {
            z-index: 100;
            user-select: none;
            position: absolute;
            top: 50px;
            right: 3px;
            max-height: 500px;
        }
        .multi-action-icon {
            margin: 0 2px;
        }
    }
}
