@import '../../style/colors.less';

.create-topology-container,
.create-watchlist-container {
    flex: 1;
    .form-container {
        padding: 5px 40px;
    }
    .upload-card {
        background: #fff;
        padding: 34px 30px 0px;
        border-radius: 2px;
        margin: 28px auto 0;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        .and-or {
            text-align: center;
            font-weight: bold;
            margin-bottom: 20px;
        }
        &.has-error {
            border: 1px solid #f44336;
        }
        .has-error {
            border: 1px solid #f44336;
        }
    }
    .v-drone-button {
        &.connected {
            border: 1px solid green;
        }
    }
    .create-topology-box-error {
        display: block;
        margin-bottom: 24px;
    }
}

.dark-theme {
    .create-topology-container,
    .create-watchlist-container {
        .form-container {
            background-image: linear-gradient(to left, #05090a, #000a1b, #081b3a, #061632, #000a1b);
        }
        .upload-card {
            background-color: @black;
            &.has-error {
                border-color: #f03b16;
            }
            .has-error {
                border-color: #f03b16;
            }
        }
    }
    .v-drone-button {
        border-color: @clickable;
        font-weight: bold;
        .drone-button-text {
            text-transform: capitalize;
            color: #2d6eba;
        }
        &.disabled {
            opacity: 0.5;
            border-color: @clickable;
        }

        &.connected {
            font-weight: bold;
            border-style: solid;
            border-width: 1px;
            border-image-source: radial-gradient(circle at 50% 5%, #72ef96 10%, rgba(0, 0, 0, 0) 40%);
            border-image-slice: 1;
            background-image: radial-gradient(circle at center, #0d384f, rgba(12, 71, 103, 0));
            .connected-text {
                color: @confirmation;
            }
        }
    }
}
