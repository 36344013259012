.edit-group-container {
    .counter-action-container {
        margin-bottom: 36px;
    }
    h5 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0;
    }
}
