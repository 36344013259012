@import (reference) '../../../style/colors';
@import (reference) '../../../style/common';
@import (reference) '../../../style/main';
@import (reference) '../../../style/variables';

.avatar {
    height: 38px !important;
    width: 38px !important;
    margin: @gap-half @gap;
    box-shadow: inset 0 0 2px 0 rgba(125, 142, 170, 0.52);
    border: solid 3px @individuals-outline-grey;
}

.avatar:hover {
    border-color: @individuals-outline-grey;
}

.vv-avatar-tag-container {
    position: absolute;
    height: 16px;
    width: 16px;
    top: 27px;
    left: 4px;
    background: black;
    border-radius: 50%;
    padding: 1px;
}

.bigAvatar {
    height: 90px !important;
    width: 90px !important;
    margin: auto;
    box-shadow: inset 0 0 2px 0 rgba(125, 142, 170, 0.52);
    border-width: 4px;
}

.gallery-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.gallery-button {
    padding: 4px;
    align-items: flex-start;
    gap: 4px;
    border-radius: 8px;
    border: 1px solid @clickable;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    color: @clickable;
    cursor: pointer;
}

.actions-icons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}
.action-icon {
    width: 35px;
    text-align: center;
}

.label-name {
    text-align: center;
    word-break: break-word;
    margin-top: 14px;
}

.synapse-header {
    min-height: @topbar-height;
    background-color: #f6f6f6;
    h3.topology-name {
        margin: auto;
        &:hover {
            text-decoration: underline;
            cursor: pointer;
            color: darken(#dedede, 10);
        }
    }
}

.topologies-link {
    .flex-center;
    margin: auto;
    text-decoration: none;
    color: black;
    font-size: 14px;
    font-weight: 600;
    span {
        margin-left: 8px;
    }
    &:hover {
        text-decoration: underline;
    }
}

.dark-theme {
    .synapse-header {
        background-color: @black;
    }
    .topologies-link {
        color: @white;
    }
    .vv-avatar-tag-container {
        background: @clickable;
    }
}
