@import '../../style/variables';
@import '../../style/colors';

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: @topbar-height;
    box-shadow: 3px 0 13px 0 rgba(155, 155, 155, 0.31);
    background-color: #ffffff;
    .logo-container {
        margin-left: 35px;
    }
}

.progress-bar-container {
    width: 250px;
}

.box {
    height: @topbar-height;
    padding: 4px 20px 6px;
    box-shadow: 0 8px 14px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
}

.dark-theme {
    .navbar {
        background-color: @black;
    }
    .box {
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        background-color: #0a133b;
    }
}
