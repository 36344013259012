@import (reference) '../../style/colors';

.virtual-scroll-image {
    cursor: pointer;
    position: relative;
    &.focused {
        box-sizing: border-box;
        outline: @light-blue 3px solid;
        outline-offset: -3px;

        &:hover {
            outline: @light-blue 4px solid;
            outline-offset: -4px;
        }
    }
    &.is-multi-selected {
        outline: @light-blue 3px solid;
        outline-offset: -3px;

        &:hover {
            outline: @light-blue 4px solid;
            outline-offset: -4px;
        }
        .multi-select-checkbox {
            visibility: visible;
        }
    }

    &.is-flagged {
        .flag-icon {
            visibility: visible;
        }
    }

    .multi-select-checkbox {
        visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 6px;
    }
    &:hover {
        outline: #f4f4f4 2px solid;
        outline-offset: -2px;

        .multi-select-checkbox,
        .flag-icon {
            visibility: visible;
        }
    }

    .flag-icon {
        visibility: hidden;
        background-color: rgba(0, 0, 0, 0.2);
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 1px;
        border-radius: 6px;
        box-sizing: content-box;
    }
    .image {
        width: 100%;
        height: 100%;
    }
}
