@import '../../style/colors.less';

.compare-container {
    flex: 1 1 0%;
    .status {
        text-align: center;
        color: @confirmation;
        margin-bottom: 8px;
    }
    .query-progress {
        width: 800px;
        margin: 0 auto;
    }
    .zip-upload-container {
        width: 500px;
        margin: 30px auto;
    }
    .error {
        color: red;
        font-weight: bold;
        text-align: center;
    }

    .MuiTableCell-head {
        color: @white;
    }
    .MuiTableCell-stickyHeader {
        background-color: @black;
    }
    .compare-result {
        margin: 10px auto;
    }
}
