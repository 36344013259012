.audit-expanded-row {
    display: flex;
    padding: 6px 64px;
    background-color: #545454;

    pre {
        font-family: Roboto, sans-serif;
    }

    table {
        border-collapse: collapse;

        tr {
            td:first-child {
                padding: 0;
                margin: 0;
                padding-right: 8px;
            }
        }
    }

    .key-column {
        margin-right: 4px;
    }
}
