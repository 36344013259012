@import (reference) '../../style/colors';

.login-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    box-shadow: 26px 26px 26px 0 rgba(0, 0, 0, 0.09), 4px 4px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: @white-two;
    .card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 680px;
        box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.08), inset 1px 0 0 0 @white-three;
        background-color: @white;
        padding-top: 40px;
        padding-bottom: 100px;
        .logo-container {
            margin-bottom: 32px;
        }
        .login-form {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .login-sso-btn {
        display: block;
        margin: 0 auto;
    }
}

.form-group {
    margin-bottom: 20px;
    label {
        height: 24px;
        opacity: 0.87;
        font-family: Roboto;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.71;
        letter-spacing: normal;
        text-align: left;
        color: #232c40;
        display: block;
    }

    .form-field {
        width: 426px;
        height: 40px;
        border-radius: 2px;
        box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.09);
        border: solid 1px @silver;
        background-color: @white;
        padding: 8px 12px;
        &:focus {
            box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.09);
            border: solid 1px @silver;
            outline: none;
        }
    }

    small {
        display: block;
        margin-top: 5px;
        color: @red-orange;
    }
}

.submit-button {
    width: 426px;
    height: 40px;
    border-radius: 2px;
    border-color: transparent;
    box-shadow: -2px 2px 8px 0 rgba(0, 0, 0, 0.08);
    background-color: #232c40;

    text-align: center;
    text-transform: uppercase;
    font-family: Roboto;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    color: @white;
    cursor: pointer;
    outline: none;
    &:active {
        border-style: outset;
    }
}

.general-error {
    color: #b94a48;
    opacity: 0.87;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.71;
    display: inline-block;
    width: 426px;
    margin-bottom: 5px;
}

.dark-theme {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        box-shadow: 0 0 0 30px lighten(#142061, 10) inset;
        -webkit-text-fill-color: #dedede;
    }

    .login-container {
        background-image: linear-gradient(to top, #05090a, #06152d, #081b3a, #0a2452, #061838, #000000);
        .card {
            background-color: @black;
            box-shadow: none;
        }
        .submit-button {
            background-color: @clickable;
        }
    }
    .form-group {
        label {
            color: #a8a8a8;
        }

        .form-field {
            border: none;
            background-color: #142061;
            color: #dedede;
            &:focus {
                border: none;
            }
        }
    }
    .general-error {
        color: #f03b16;
        opacity: unset;
    }
}
