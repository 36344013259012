@import (reference) '../../style/common';
@import (reference) '../../style/colors';

.topologies-container {
    padding: 5px 40px;
    font-family: @font-main;
    display: flex;
    flex-direction: column;

    .clip-loader-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
