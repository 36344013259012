.all-media-filters-container {
    width: 100%;

    .objects-filter-label {
        display: flex;
        align-items: center;
        width: 100%;

        svg {
            margin-left: 6px;
        }
    }
}
