@import (reference) '../../style/colors';

.popup-image-footer-wrapper {
    margin-bottom: 30px;
    .popup-image-footer-general {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 40px;

        padding: 0 12px;
        border-radius: 5px;
        border: solid 1px #303a49;
        background-color: #131923;

        .primary-text {
            display: inline-flex;
            align-items: center;
            font-size: 14px;
            color: @white;
            margin-right: 16px;
        }

        .secondary-text {
            font-size: 12px;
            color: #dedede;
        }

        .photo-icons {
            display: flex;
            align-items: center;
            margin-left: auto;
        }
    }

    .popup-image-footer-metadata-header {
        background-color: black;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 6px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        cursor: pointer;
        .title {
            display: flex;
            align-items: center;
            .primary-text {
                font-size: 16px;
                color: @white;
                font-weight: 500;
            }
        }
    }

    .popup-image-footer-metadata-content {
        padding: 14px 18px;

        .primary-text {
            font-size: 14px;
            color: @white;
            font-weight: 500;
            text-transform: capitalize;
            flex-shrink: 0;
            &:after {
                content: ':';
                display: inline-block;
                margin-left: 2px;
                margin-right: 5px;
            }
        }

        .secondary-text {
            font-size: 14px;
            font-weight: 300;
            color: @white;
            opacity: 0.8;
            max-height: 500px;
            overflow-y: auto;
        }
    }

    .popup-image-footer-metadata-item {
        display: flex;
        margin-bottom: 6px;

        &.vendor {
            display: inline-flex;
        }

        &.type {
            display: inline-flex;
            align-items: center;
            color: @white;
            &:before {
                content: '(';
                display: inline-block;
                font-weight: 300;
                margin-left: 10px;
            }
            &:after {
                content: ')';
                display: inline-block;
                font-weight: 300;
                margin-right: 5px;
                opacity: 0.8;
            }
            .primary-text {
                font-weight: 300;
                opacity: 0.8;
            }
        }
    }
}

.popup-image-footer-tooltip-list {
    margin-bottom: 0;
}

.popup-image-footer-icon:not(:last-child) {
    margin-right: 8px;
}
