@import '../../../style/colors.less';

.advanced-settings-form {
    width: 700px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    .buttons {
        display: flex;
        margin-left: auto;
        button {
            width: 180px;
        }
        .apply-button {
            margin-left: 10px;
        }
    }

    .processing-mode {
        background-color: @black;
        padding: 28px 60px;
        margin-bottom: 20px;
    }

    .processing-mode-title {
        text-transform: uppercase;
        font-size: 16px;
        display: block;
    }

    .processing-mode-option {
        display: flex;
        align-items: center;
        .MuiFormControlLabel-root {
            margin-right: 8px;
        }
    }

    .fr-mode {
        margin: 0 56px;
        border-bottom: @dividers 1px solid;
        margin-bottom: 20px;
    }

    .video-settings {
        margin: 0 56px;
        margin-bottom: 15px;

        .slicing-section {
            border-bottom: @dividers 1px solid;
            margin-bottom: 10px;
        }
    }

    .MuiCheckbox-root,
    .MuiRadio-root {
        padding-right: 6px;
    }
}
