.side-filter-container {
    width: 320px;
    height: 90vh;
    padding: 20px;
    display: flex;
    flex-direction: column;
    .header {
        margin-bottom: 30px;
    }

    .buttons-container {
        justify-content: flex-end;
        margin: auto 0 0 0;
        .clear-button {
            margin-right: 8px;
        }
    }
}
