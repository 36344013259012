@import (reference) '../../style/colors';
@import (reference) '../../style/variables';

.popup-image {
    &-container {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &-header-container {
        margin-bottom: @gap-double;
    }

    &-wrapper {
        display: flex;
        flex: 1;
        min-height: 0;
        margin-bottom: 30px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &-split {
        text-align: center;
        align-self: flex-end;
    }

    &-split-amount {
        font-size: 16px;
        font-weight: 500;
        color: @clickable;
    }
}
