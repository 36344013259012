@import (reference) '../../style/colors';
@import (reference) '../../style/common';
@import (reference) '../../style/main';

.sidebar {
    background-color: @white-three;
    height: ~'calc(100vh - @{topbar-height})';

    flex: 0 0 270px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .sidebar-navigation {
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;

        a {
            text-decoration: none;
        }

        .sidebar-navigation-item {
            padding: 17px 0 19px 69px;

            &.active {
                background-color: @geyser;
            }

            &:hover {
                background-color: @geyser;
            }
        }
    }

    .help {
        margin: auto 0 19px 33px;
        font-size: 14px;
        .link {
            .flex-align-center;
            color: @clickable;
            .icon {
                margin-right: 17px;
            }
        }
    }

    .settings-container {
        border-top: 1px solid @silver;
        width: 270px;
        background-color: rgba(72, 125, 189, 0.5);

        ul.settings-list {
            color: @white;
            list-style-type: none;
            padding: 19px 19px 0 33px;
            margin: 0 0 9px;

            li {
                display: flex;
                width: 100%;
                margin-bottom: 8px;
                overflow: hidden;
                .text-container {
                    display: flex;
                    align-items: center;
                    width: calc(100% - 19px);
                    height: 32px;
                    flex-shrink: 1;
                    margin-left: 16px;
                }

                .text-after-icon {
                    font-family: 'Roboto';
                    font-size: 14px;
                    font-weight: bold;
                    opacity: 0.87;
                    color: #fff;
                    cursor: pointer;
                    white-space: nowrap;
                }
            }

            .settings-list-icon {
                flex-shrink: 0;
            }

            .settings-list-select {
                .MuiInput-underline:before,
                .MuiInput-underline:after {
                    display: none;
                }
            }
        }
    }

    .group-name-container {
        display: inline-block;
        width: 100%;
        overflow: hidden;
        .MuiFormControl-root {
            max-width: 100%;
        }
    }
}

.dark-theme {
    .sidebar {
        background-color: @black;
        .sidebar-navigation {
            .sidebar-navigation-item {
                font-weight: normal;
                color: #bdbdbd;
                border-top: 1px solid @dividers;
                border-bottom: 1px solid @dividers;
                &:hover {
                    color: @clickable;
                    background-color: unset;
                }
            }

            .active {
                .sidebar-navigation-item {
                    border-left: 4px solid @light-blue;
                    background-image: linear-gradient(to right, #0d384f, rgba(12, 71, 103, 0));
                    font-size: 14px;
                    font-weight: bold;
                    color: @white;
                    &:hover {
                        color: @clickable;
                    }
                }
            }
        }

        .settings-container {
            border-top: 2px solid @dividers;
            background-color: @black;

            ul.settings-list {
                li {
                    .text-after-icon {
                        color: rgba(255, 255, 255, 0.6);
                        font-size: 13px;
                        font-weight: 500;
                    }
                    &.action {
                        fill: @individuals-outline-grey;
                        &:hover {
                            & .text-after-icon {
                                color: @clickable;
                            }
                            fill: @clickable;
                        }
                    }
                }
            }
        }
    }
}
