@import (reference) '../../../style/colors';

.dropdown-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    width: 100%;
    border-radius: 4px;
    border: solid 1px @individuals-outline-grey;
    background-color: @black;
    position: relative;

    .dropdown-content {
        position: absolute;
        top: 40px;
        z-index: 10;
    }
}
