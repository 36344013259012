@import (reference) '../../../style/colors';
@import (reference) '../../../style/common';
@import (reference) '../../../style/variables';

.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-weight: bold;
    .version {
        font-family: @font-main;
        color: @white;
        font-weight: 300;
        font-size: 14px;
        margin-left: @gap * 1.5;
        padding-bottom: 5px;
        align-self: flex-end;
    }
}
