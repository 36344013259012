@import (reference) '../../style/colors';

.fullscreen {
    @outline-width: 3px;
    position: relative;
    height: 100%;
    width: 100%;
    min-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    .image-loader {
        position: absolute;
        top: 50;
        left: 50;
        z-index: 100;
    }
    &.fullscreen-enabled {
        .image {
            max-width: 100vw;
            max-height: 100vh;
        }
        .canvas {
            outline: none;
        }
    }
    .full-size-icon {
        position: absolute;
        z-index: 10;
        margin: 3px;
        display: none;
        &:hover {
            display: unset;
        }
    }

    .image {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        display: block;
        user-select: none;
        visibility: hidden;
    }

    .canvas {
        outline: solid @outline-width #56b1f9;
        position: absolute;
        top: 0;

        &.hide {
            visibility: hidden;
        }

        &:hover + .full-size-icon {
            display: unset;
        }
    }
}
