@import (reference) '../../style/colors';
@import (reference) '../../style/variables';

.popup-gallery {
    &-container {
        display: flex;
        flex-direction: column;
        flex: 1 1 0;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.9);
    }

    &-header {
        margin-bottom: @gap-double;
    }

    &-content {
        display: flex;
        flex: 1;
        min-height: 0;
        min-width: 0;
        padding: 0 @gapLayoutHorizontal;
    }

    &-virtual-scroll-gallery-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 630px;
        margin-right: 12px;
    }

    &-split {
    }

    &-split-title {
        font-size: 16px;
        font-weight: 400;
        color: @white;
        margin-top: 0;
        margin-bottom: @gap;
    }

    &-split-subtitle {
        font-size: 14px;
        font-weight: 500;
        color: @voyagerGray;
        margin-top: 0;
        margin-bottom: @gap;
    }

    &-image-container {
        min-height: 0;
        min-width: 0;
        flex: 1;
        margin-bottom: calc(@gap-half * 3);
    }
}
