@import (reference) '../../style/common';

.audit-container {
    padding: 5px 40px;
    font-family: @font-main;
    display: flex;
    flex-direction: column;

    .show-all-groups {
        margin-bottom: 12px;
    }

    .category-selector {
        display: flex;
        align-items: center;
        margin-bottom: 6px;
        :first-child {
            margin-right: 8px;
        }
    }

    ::-webkit-scrollbar-thumb {
        background-color: #545454;
    }

    nav select {
        background-color: #424242;
    }
}

.with-before {
    & ~ &:before {
        content: ', \a';
        display: inline;
    }
}
