@import (reference) 'colors';
@import (reference) 'common';
@import (reference) 'variables';

body,
html {
    margin: 0;
    padding: 0;
    .custom-scrollbar;
    font-family: @font-main;
}

body {
    background-image: linear-gradient(to left, #05090a, #000a1b, #081b3a, #061632, #000a1b);
}

* {
    box-sizing: border-box;
}

h3,
h4 {
    //font-family: @font-main;
    font-family: Roboto-Black, sans-serif;
    font-size: 18px;
    font-weight: 900;
    line-height: 1.5;
    color: @mariner;
    margin-bottom: 28px;
    text-transform: uppercase;
}

main.main {
    height: 100%;
}

main.main.with-topbar {
    height: ~'calc(100vh - @{topbar-height})';
}

.scrollable-container {
    height: ~'calc(100vh - @{topbar-height})';
    overflow-y: auto;
}

button:disabled {
    cursor: not-allowed;
}

// ----- flex utils ----- //
.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-justify-center {
    display: flex;
    justify-content: center;
}

.justify-start {
    justify-content: start;
}

.flex-justify-between {
    display: flex;
    justify-content: space-between;
}

.flex-align-center {
    display: flex;
    align-items: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-background {
    height: 100%;
    color: #fff;
}

.MuiSnackbarContent-message {
    width: 100%;
}

.text-red {
    color: #f44336;
}

.full-width {
    width: 100%;
}

.file-upload-button {
    width: 100%;
    height: 35px;
}

.auto-complete {
    .image-gallery-topbar {
        .Mui-focused.MuiInputLabel-outlined {
            display: none;
        }
    }
    .MuiAutocomplete-popupIndicator {
        color: @clickable;
    }

    .MuiInputLabel-outlined {
        transform: translate(14px, 14px) scale(1);
        font-size: 13px;
    }
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
        padding: 2px;
        padding-right: 62px;
    }
    .MuiInputBase-input {
        font-size: 13px;
    }
}

.vv-progress-bar-counter {
    .counter-top {
        margin-bottom: 4px;
        font-size: 13px;
        .vv-label {
            height: 20px;
            opacity: 0.87;
            flex-grow: 1;
            font-size: 12px;
            line-height: 1.7;
            font-weight: bold;
            color: #c6c6c6;
            text-transform: uppercase;
        }
        .current-total-container {
            display: flex;
            align-items: center;
            color: #4a90e2;
            font-size: 9px;
            font-weight: 500;
            margin: 0 0 4px;
            .current {
                font-weight: 500;
            }
        }
    }
    .counter-bottom {
        width: 220px;
        height: 17px;
        background-color: #ffffff;
        border-radius: 1px;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
        align-items: center;
        .bar-container {
            width: 210px;
            height: 5px;
            background-color: #d6d6d6;
        }
        .bar {
            width: 0;
            height: 5px;
            background-color: #4a90e2;
        }
    }
}

.create-topology-form {
    display: flex;
    flex-direction: column;
    height: ~'calc(100vh - @{topbar-height} - 10px)';
}

.content-item {
    padding: 6px;
    background-color: @wild-sand;
    margin-bottom: 19.6px;
    border-radius: 2px;
    opacity: @font-opacity;
    align-items: center;
    .action {
        display: none;
        padding: 8px;
    }
    &:hover {
        .action {
            display: block;
        }
    }
    &.clickable {
        cursor: pointer;
    }
    &.clickable:hover {
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.4);
    }

    .item-image {
        border: solid 2.5px #a8a8a8;
        background-color: rgba(196, 196, 196, 0);
        box-sizing: border-box;
    }

    .item-title {
        margin: 4px 0;
        opacity: 1;
        font-size: 16px;
        font-weight: bold;
        color: black;
    }

    .item-second-line-btn {
        height: 24px;
        border: 1px solid rgba(86, 87, 251, 0.6);
    }

    .item-details {
        margin-top: 6px;
        display: flex;
        align-items: center;

        .field {
            opacity: 0.87;
            font-size: 11px;
            color: #989898;
            margin: 0 6px;
            &:first-child {
                margin: 0 6px 0 0;
            }
            .field-name {
                font-weight: 300;
            }
            .field-value {
                font-weight: 600;
            }
        }
    }
}

.topology-list--item--delete {
    display: none;
    float: right;
    margin: auto;
    margin-right: 2em;
    padding: 5px;
    &:hover {
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.4);
    }
}

&:hover {
    .delete-action-container span {
        visibility: visible;
    }

    .topology-list--item--delete {
        display: block;
    }
}

.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-center {
    text-align: center;
}

.text-capitalize {
    text-transform: capitalize;
}

.dark-theme {
    color: #fff;
    background-image: linear-gradient(to left, #05090a, #000a1b, #081b3a, #061632, #000a1b);
    h4,
    h3 {
        font-family: Roboto, sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #dedede;
    }
    .scrollable-container {
        background-image: linear-gradient(to left, #05090a, #000a1b, #081b3a, #061632, #000a1b);
    }
    .file-upload-button {
        border-color: @clickable;
        font-weight: bold;
        &.disabled {
            border-color: @clickable;
            opacity: 0.5;
        }
    }
    .text-red {
        color: #f03b16;
    }

    .query-progress {
        background-color: #848484;
        .MuiLinearProgress-barColorPrimary {
            background-color: @light-blue;
        }
    }

    .content-item {
        background-color: @black;
        opacity: @font-opacity;
        .action {
            display: none;
            padding: 8px;
        }
        &:hover {
            .action {
                display: block;
            }
        }
        &.clickable {
            cursor: pointer;
        }
        &.clickable:hover {
            box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.4);
        }

        .item-image {
            border: solid 2.5px #a8a8a8;
            background-color: rgba(196, 196, 196, 0);
        }

        .item-title {
            opacity: 1;
            color: #dedede;
        }

        .item-details {
            .field {
                opacity: unset;
                font-size: 11px;
                color: #dedede;

                .field-name {
                    font-weight: 400;
                    color: @individuals-outline-grey;
                }
                .field-value {
                    font-weight: 500;
                }
            }
        }
    }

    .vv-progress-bar-counter {
        .counter-top {
            .vv-label {
                opacity: 1;
                font-size: 12px;
                font-weight: 500;
                color: @individuals-outline-grey;
            }
            .current-total-container {
                color: #dedede;
                font-size: 10px;
                font-weight: 500;
            }
        }
        .counter-bottom {
            background-color: #11225e;
            .bar-container {
                background-color: #848484;
            }
            .bar {
                background-color: @light-blue;
            }
        }
    }

    .text-button {
        font-weight: 500;
        font-size: 14px;
        text-decoration: underline;
        color: @clickable;
        cursor: pointer;
    }
}

.dark-modal,
.dark-theme {
    .MuiCheckbox-root {
        color: @clickable;
    }
    .MuiSelect-icon {
        color: @clickable;
    }
    div.MuiAutocomplete-tag {
        background-color: @hover-on-icon;
    }
    .MuiChip-deleteIcon {
        color: @clickable;
    }
}

.clickable-color {
    color: @clickable;
}

ul .video-metadata {
    margin-bottom: 6px;
}

.search-container {
    .MuiInputLabel-outlined {
        transform: translate(14px, 14px) scale(1);
    }
}
