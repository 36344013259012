@import '../../style/colors.less';

.warning-dialog {
    .warning-dialog-title {
        font-size: 16px;
        font-weight: bold;
    }

    .warning-dialog-close {
        position: absolute;
        right: 1px;
        top: 1px;
    }

    .warning-dialog-content {
        background-color: @black;
    }

    .warning-dialog-description {
        font-size: 14px;
    }
}
