@import (reference) '../../../style/colors';
@import (reference) '../../../style/common';
@import (reference) '../../../style/variables';

.side-panel-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: @topbar-height;
    }
    .after-logo {
        overflow-y: auto;
        overflow-x: hidden;
        height: ~'calc(100vh - @{topbar-height})';
    }

    .side-panel-section {
        &:first-child {
            padding: @gap @gap-double 0 @gap-double;
        }

        &:not(:first-child) {
            padding: @gap-double @gap-double 0 @gap-double;
        }

        .search-header-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: @gap-double;
            .side-panel-section-header {
                margin-bottom: 0;
            }
            .search-header-filters {
                width: 36px;
                height: 36px;
            }
        }

        .watchlist-results,
        .cross-topology-results {
            margin-top: @gap-double;
        }
    }

    .side-panel-watchlist,
    .side-panel-cases {
        margin-top: @gap-double;
        border-top: 1px solid @silver;
    }

    .side-panel-section-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        font-weight: 700;
        color: @white-three;
        text-align: left;
        margin-bottom: @gap-double;
    }

    .avatar-title-wrapper {
        display: flex;
    }

    .avatar-title-text {
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .side-panel-header {
        font-size: 14px;
        font-weight: 500;
        color: @text-secondary;
        margin-top: 0;
        margin-bottom: @gap;
    }

    .side-panel-subheader {
        font-size: 12px;
        color: @text-secondary;
        margin-right: @gap;
    }

    .side-panel-individuals-sorting {
        padding-top: @gap;
    }

    .individuals-sorting-switch {
        flex-wrap: wrap;
        &-label,
        &-group {
            margin-top: @gap;
        }
    }

    .key-individuals-top {
        flex-wrap: wrap;
        align-items: center;
    }

    .selected-buttons-wrapper {
        display: flex;
        flex-direction: row;
        position: relative;
    }

    .clear-and-cancel-wrapper {
        right: 0;
        position: absolute;
        display: flex;
        flex-direction: row;
    }

    .selected-images-counter {
        color: @gray2;
        text-align: center;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        align-self: stretch;
    }

    .selected-button {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        color: @clickable;
        cursor: pointer;
        margin-bottom: 8px;
    }

    .cancel-button {
        margin-left: 10px;
    }

    .key-individuals-button {
        margin-left: auto;
    }

    .key-individuals-all {
        display: flex;
        flex-wrap: wrap;
        margin-top: @gap;
        margin-bottom: @gap;
        .key-individuals-chip {
            font-size: 11px;
            margin-right: 7px;
            margin-bottom: 4px;
        }
        .MuiChip-outlined {
            transition: all 280ms;
            &:hover {
                color: @white;
                background-color: @clickable-hover;
            }
        }
    }

    .key-individuals-chip {
        border: 1px solid transparent;
    }

    .cross-topology-results .side-panel-subheader {
        margin-bottom: @gap;
    }
}

.dark-theme {
    .side-panel-container {
        background-color: @black;
    }

    .side-panel-section {
        border-color: @dividers;
    }
}
