@import (reference) '../../../style/colors';

.virtualized-auto-complete {
    width: 100%;
    position: relative;

    .auto-complete-input {
        width: 100%;
        background-color: @dark-gray;
        color: #e5e5e5;
        padding: 8px 10px 8px 30px;
        font-size: 14px;
        outline: 0;
        height: 38px;
        border-radius: 65px;
        box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.15);
        border: solid 2px @clickable;
    }

    &-label {
        display: flex;
        align-items: center;
        .popup-gallery-header-search & {
            margin-top: -4px;
        }
    }
}
