.drawer-container {
    .panel {
        min-height: 66px;
        padding: 10px 20px;
        display: flex;
        align-items: center;

        .persons {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex-grow: 1;
            margin: 0 15px;
        }
        .see-all-button {
            font-size: 12px;
            margin: 0 10px;
        }
        .close-icon {
            margin-left: 12px;
        }

        &.open {
            min-height: 132px;
            flex-wrap: wrap;
            .selected-count {
                flex-grow: 1;
            }
            .persons {
                margin: 10px 0;
                order: 4;
                flex-grow: 0;
                max-height: 150px;
                min-width: 100%;
                overflow-y: auto;
            }
            .see-less-button {
                order: 5;
                margin: 0 10px;
                cursor: pointer;
            }
        }
    }
}
