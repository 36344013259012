.gallery-tab-container {
    display: flex;
    flex-direction: column;
    @columnMargin: 16px;
    @columnWidth: 236px;
    @screenWithoutColumnsWidth: 346px;
    @columnTotalSize: @columnMargin + @columnWidth;

    @1column: @columnTotalSize;
    @2columns: @columnTotalSize * 2;
    @3columns: @columnTotalSize * 3;
    @4columns: @columnTotalSize * 4;
    @5columns: @columnTotalSize * 5;
    @6columns: @columnTotalSize * 6;
    @7columns: @columnTotalSize * 7;
    @8columns: @columnTotalSize * 8;
    @9columns: @columnTotalSize * 9;
    @10columns: @columnTotalSize * 10;
    @11columns: @columnTotalSize * 11;

    height: 100%;
    width: @columnTotalSize * 16;
    margin: 0 auto;

    @media (min-width: 0px) and (max-width: @screenWithoutColumnsWidth + @2columns) {
        width: @1column;
    }

    @media (min-width: @screenWithoutColumnsWidth + @2columns) and (max-width: @screenWithoutColumnsWidth + @3columns) {
        width: @2columns;
    }

    @media (min-width: @screenWithoutColumnsWidth + @3columns) and (max-width: @screenWithoutColumnsWidth + @4columns) {
        width: @3columns;
    }

    @media (min-width: @screenWithoutColumnsWidth + @4columns) and (max-width: @screenWithoutColumnsWidth + @5columns) {
        width: @4columns;
    }

    @media (min-width: @screenWithoutColumnsWidth + @5columns) and (max-width: @screenWithoutColumnsWidth + @6columns) {
        width: @5columns;
    }

    @media (min-width: @screenWithoutColumnsWidth + @6columns) and (max-width: @screenWithoutColumnsWidth + @7columns) {
        width: @6columns;
    }

    @media (min-width: @screenWithoutColumnsWidth + @7columns) and (max-width: @screenWithoutColumnsWidth + @8columns) {
        width: @7columns;
    }

    @media (min-width: @screenWithoutColumnsWidth + @8columns) and (max-width: @screenWithoutColumnsWidth + @9columns) {
        width: @8columns;
    }

    @media (min-width: @screenWithoutColumnsWidth + @9columns) and (max-width: @screenWithoutColumnsWidth + @10columns) {
        width: @9columns;
    }

    @media (min-width: @screenWithoutColumnsWidth + @10columns) and (max-width: @screenWithoutColumnsWidth + @11columns) {
        width: @10columns;
    }
}
