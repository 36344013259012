@import (reference) '../../../style/colors';

.tree-view {
    border-radius: 4px;
    border: solid 1px rgba(255, 255, 255, 0.32);
    background-color: #000000;
    z-index: 20;
    color: #dedede;
    overflow-y: auto;
    max-height: 100%;

    .MuiTreeItem-iconContainer {
        display: none;
    }

    > .tree-item {
        padding: 0 8px;
    }

    .tree-item {
        color: rgba(255, 255, 255, 0.6);
        background-color: #000000;

        .MuiTreeItem-content .MuiTreeItem-label {
            background-color: unset !important;
        }
        border-bottom: #000000 1px solid;
        &.selected {
            background-color: #0e1923;
            color: @white;
        }
    }
}
