@import (reference) '../../style/colors';

.virtual-scroll-gallery {
    flex-grow: 1;
    margin-top: 8px;
    .image-measurer-container {
        height: 100%;

        width: 100%;
    }
    .masonry {
        outline: none;
    }
}
