@import (reference) '../../../style/colors.less';

.export-query-modal {
    .filters-container {
        margin-bottom: 6px;
    }
    .export-or-cancel-button-container {
        margin-bottom: 10px;
        button {
            width: 100px;
        }
    }

    .export-data {
        .export-data-title {
            font-weight: bold;
            .status {
                text-transform: capitalize;
                &.finished {
                    color: @confirmation;
                }
                &.error {
                    color: @error;
                }
                &.processing {
                    color: @light-blue;
                }
                &.stopped {
                    color: @individuals-outline-grey;
                }
                &.expired {
                    color: @individuals-outline-grey;
                }
            }
        }
    }

    .divider {
        margin: 10px 0;
    }
    .exports-title {
        margin-bottom: 8px;
        font-weight: bold;
    }
}
