@import (reference) '../../style/common';

.users-management-container {
    padding: 5px 40px;
    font-family: @font-main;
    display: flex;
    flex-direction: column;

    ::-webkit-scrollbar-thumb {
        background-color: #545454;
    }

    .tabs-wrapper {
        display: flex;
        justify-content: space-around;

        .category-tab {
            font-size: 20px;
            color: gray;
            cursor: pointer;
            flex-grow: 1;
            text-align: center;
            border-bottom: 1px #636161 solid;
            padding-bottom: 4px;

            &:hover {
                color: white;
                border-bottom: 1px white solid;
            }

            &.active {
                color: white;
                border-bottom: 3px solid @clickable;
            }
        }
    }

    .edit-user-form {
        border: 1px #7a7d7f solid;
        border-radius: 8px;
        padding: 16px;
        margin-top: 18px;
        .user-groups-dropdown {
            display: flex;
            align-items: center;
            justify-content: space-between;
            > .auto-complete {
                margin: 12px 0 22px 0 !important;
                width: 75% !important;
            }
        }
    }

    nav select {
        background-color: #424242;
    }
}

.users-management {
    &-subtitle {
        font-size: 16px;
        margin-bottom: 0;
    }
    &-label {
        white-space: nowrap;
    }
}
